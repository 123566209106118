
<script setup lang="ts">
    import { computed } from "vue";
    import { isMobile } from "o365.GlobalState.ts";
    import { useAsyncComponent } from "o365-vue-utils";

    export interface IProps {
        title: string;
        href?: string;
    };

    const MCard = useAsyncComponent("o365-mobile/MCard", { importFn: async () => (await import("o365-mobile")).MCard });

    const props = defineProps<IProps>();
</script>

<template>
    <template v-if="isMobile">
        <a class="" style="margin: 0 -0.5rem; padding: 0.25rem 0.5rem;" :title="title" :href="href">
            <div>{{ title }}</div>
        </a>
    </template>
    <template v-else-if="isMobile">
        <MCard :title="title" :href="href" shadow="none" />
    </template>
    <template v-else>
        <div>
            <a class="d-inline-block" :title="title" :href="href">
                <div>{{ title }}</div>
            </a>
        </div>
    </template>
</template>

<style scoped>
    .c-section-item {

    }

    .c-section-item:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .c-section-item:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .c-section-item:not(:last-child) {
        border-bottom: none !important;
    }
</style>
